<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-16 10:00:28
 * @LastEditTime: 2020-05-11 10:01:12
 -->
<template>
  <div class="jepass-invoice-details">
    <div class="je-detail-content padding-top-15">
      <div class="padding-LR-20 padding-TB-20 background-FFFFFF border-radius-10 position-relative">
        <i
          v-if="contractDetails.HTGL_HTZT_CODE == 'YYJ'"
          class="jeicon jeicon-complete icon-top-show"
        />
        <div class="color-3F3F3F font-weight-600">
          <span class="font-size-30">合同详情</span>
          <span class="font-size-18 margin-left-40">{{ contractDetails.HTGL_HTDH || '' }}</span>
        </div>
        <div class="color-A9ABA9 font-size-14 margin-top-5">
          <span>下单时间：{{ contractDetails.SY_CREATETIME || '' }}  </span>
        </div>
        <div class="color-3F3F3F font-weight-600 font-size-18 margin-top-15">
          合同状态：{{ contractDetails.HTGL_HTZT_NAME || '' }}
        </div>
      </div>
      <!-- 表格内容 -->
      <div class="margin-top-20 min-height-600 background-FFFFFF">
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="date"
            align="center"
            header-align="center"
            show-overflow-tooltip
            width="220"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <span class="font-size-18">订单日期</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            align="center"
            header-align="center"
            show-overflow-tooltip
            width="220"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <span class="font-size-18">订单编号</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            header-align="center"
            show-overflow-tooltip
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <span class="font-size-18">商品名称</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            show-overflow-tooltip
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <span class="font-size-18">金额</span>
            </template>
            <template slot-scope="scope">
              <span style="color: #F9514F">￥{{ scope.row.price.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <template slot="empty">
            <img
              src="../../../../detail/assets/images/alreadyIndent/nullData.png"
              alt
            >
            <div class="color-BEBEBE font-size-20">
              暂无数据
            </div>
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getContractDetail } from '@/pages/index/actions/actions';
import { isArray } from '../../../../../util/util';

export default {
  props: {},
  data() {
    return {
      contractDetails: {},
      tableData: [],
    };
  },
  created() {
    if (this.$route.params.item) {
      this.contractDetails = this.$route.params.item;
      sessionStorage.setItem('contractDetails', JSON.stringify(this.$route.params.item));
    } else {
      const data = JSON.parse(sessionStorage.getItem('contractDetails'));
      this.contractDetails = data;
    }
    this.getDetail();
  },
  methods: {
    getDetail() {
      getContractDetail({ contractId: this.contractDetails && this.contractDetails.JEPAAS_HTGL_ID }).then((result) => {
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message,
          });
        }
        const arr = isArray(result.obj) ? result.obj : [];
        arr.forEach((item) => {
          item.date = item.values.DDGL_XDSJ || '未知';
          item.code = item.values.DDGL_DDH || '未知';
          item.name = item.values.DDGL_CPMC || '未知';
          item.price = item.values.DDGL_DDJG || '未知';
        });
        this.tableData = arr;
      });
    },
  },
};
</script>

<style lang="less" scoped>
  @import "../../../style/shop/util";
  .jepass-invoice-details {
    .icon-top-show {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 40px;
      color: #2FB82D;
    }
  }
</style>
<style lang="less">
  .jepass-invoice-details {
    .je-detail-content{
      margin: 0 auto;
      width: 1250px;
      .el-table thead {
        font-weight: 600;
        color: #3f3f3f;
        font-size: 16px;
      }
      .el-table__empty-text {
        line-height: normal;
        padding: 180px 0;
      }
    }
  }
</style>
